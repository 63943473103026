<template>
  <div class="deep-dive-team">
    <div
      v-show="isInRandomOrder"
      class="people"
      :style="{ '--cols': data.people.length }"
    >
      <div
        v-for="(p, index) in data.people"
        :key="`person-${p.name}-${index}`"
        role="group"
        class="person"
        :style="{ order: order[index] }"
      >
        <Image v-if="p.image_3_4" class="image" :image="p.image_3_4" />
        <p v-if="p.name" class="name text-md">
          {{ p.name }}<br />
          <span v-if="p.role" class="role">{{ p.role }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DeepDiveTeamFragment } from '#gql';
import { useShuffle } from '~/utils/useShuffle';
import Image from '~/components/partials/Image.vue';

const props = defineProps<{
  data: DeepDiveTeamFragment;
}>();

const order = ref<number[]>(props.data.people.map((p, i) => i));
const isInRandomOrder = ref<boolean>(false);

onMounted(() => {
  order.value = useShuffle(order.value);
  isInRandomOrder.value = true;
});
</script>

<style scoped lang="scss">
.deep-dive-team {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
  padding: var(--base-component-padding-block-deep-dive) 0;

  > .text {
    width: 50ch;
    max-width: 60vw;
  }

  > .people {
    --cols: 13;

    display: grid;
    grid-template-columns: repeat(var(--cols), auto);
    grid-template-rows: var(--height);
    gap: 5rem;
    height: var(--height);
    align-items: center;

    @media (--vs) {
      // --height: calc(100svh - 5rem);
      height: auto;
      grid-template-columns: 1fr;
      padding-inline: var(--base-component-padding-inline);
    }

    @media (--vl) {
      --height: calc(
        var(--app-height) - calc(
            2 * var(--base-component-padding-block-deep-dive)
          )
      );
    }

    > .person {
      gap: 1rem;
      transform: translateY(-5%);

      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (--vs) {
        height: calc(var(--height) * 0.6);
        width: calc(var(--height) * 0.4);
      }

      @media (--vl) {
        height: calc(var(--height) * 0.75);
        width: calc(var(--height) * 0.5);
      }

      > :deep(.image) {
        margin: auto auto auto 0;
        border-radius: 0.5rem;
        overflow: hidden;
      }

      > .name {
        > .role {
          font-weight: 500;
          color: rgba(198, 198, 198, 1);
        }
      }
    }
  }
}
</style>
