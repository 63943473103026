<template>
  <div
    ref="showreelEl"
    class="content-showreel"
    :class="{ '-visible': wasInViewport }"
  >
    <figure class="showreel video">
      <video
        @click="togglePlayPause"
        v-if="
          data.videoType === 'custom' &&
          data.videoCustom &&
          data.videoCustom.video
        "
        ref="videoElement"
        oncontextmenu="return false;"
        controlsList="nodownload"
        :poster="data.videoCustom.video.thumbnailUrl"
        class="video"
        :src="String(data.videoCustom.video.mp4Url)"
      />
      <div
        ref="videoControlsEl"
        :class="{ '-visible': controlsVisible }"
        class="custom-video-controls"
      >
        <div class="play-pause-button">
          <button @click="togglePlayPause">
            <PauseIcon v-if="isPlaying" />
            <PlayIcon v-else />
          </button>
        </div>
        <div ref="progress" class="progress">
          <div ref="progress" class="progress">
            <div
              class="progress-bar"
              :style="{ width: progressValue + '%' }"
            ></div>
          </div>
        </div>
        <div class="mute-button">
          <button @click="toggleMute">
            <MuteIcon v-if="isMuted" />
            <UnmuteIcon v-else />
          </button>
        </div>
      </div>

      <figcaption v-if="data.videoCaption" class="caption text-md">
        {{ data.videoCaption }}
      </figcaption>
    </figure>
  </div>
</template>

<script setup lang="ts">
import type { ContentVideoFragment } from '#gql';
import PlayIcon from '../partials/icons/PlayIcon.vue';
import PauseIcon from '../partials/icons/PauseIcon.vue';
import MuteIcon from '../partials/icons/MuteIcon.vue';
import UnmuteIcon from '../partials/icons/UnmuteIcon.vue';

const props = defineProps<{
  data: ContentVideoFragment;
}>();

const videoElement = ref<HTMLVideoElement | null>(null);
const showreelEl = ref<HTMLDivElement | null>(null);
const isVisible = ref<boolean>(false);
const wasInViewport = ref<boolean>(false);
const videoControlsEl = ref<HTMLDivElement | null>(null);
const controlsVisible = ref<boolean>(false);
const isMuted = ref<boolean>(true);
const isPlaying = ref<boolean>(true);
const progress = ref<HTMLProgressElement | null>(null);
const progressValue = ref<number>(0);

useIntersectionObserver(
  showreelEl,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;
    if (isVisible.value) {
      wasInViewport.value = true;
    }
  },
  {
    threshold: 0.3,
    rootMargin: '-100px',
  },
);

onMounted(function () {
  if (videoElement.value) {
    if (props.data.videoControls) {
      controlsVisible.value = true;
    }

    if (props.data.videoLoop) {
      videoElement.value.loop = true;
    }

    if (props.data.videoAutoplay) {
      videoElement.value.muted = true;
      videoElement.value.playsInline = true;
      videoElement.value.autoplay = true;

      videoElement.value.play();
    }
  }

  if (videoElement.value) {
    videoElement.value.addEventListener('timeupdate', () => {
      if (videoElement.value && videoElement.value.duration) {
        progressValue.value =
          (videoElement.value.currentTime / videoElement.value.duration) * 100;
      }
    });
  }

  progress.value?.addEventListener('click', handleProgressClick);
});

const handleProgressClick = (e: MouseEvent) => {
  if (!progress.value || !videoElement.value) return;
  const rect = progress.value.getBoundingClientRect();
  const pos = (e.clientX - rect.left) / rect.width;
  videoElement.value.currentTime = pos * videoElement.value.duration;
};

const toggleMute = () => {
  if (videoElement.value) {
    isMuted.value = !isMuted.value;
    videoElement.value.muted = isMuted.value;
  }
};

const togglePlayPause = () => {
  if (videoElement.value) {
    isPlaying.value = !isPlaying.value;
    if (isPlaying.value) {
      videoElement.value.play();
    } else {
      videoElement.value.pause();
    }
  }
};
</script>

<style scoped lang="scss">
.content-showreel {
  padding: var(--base-component-padding);
  max-width: 132ch;
  margin: auto;

  &.-visible {
    --scale: 1;
    --inset-x: 0%;
    --inset-y: 0%;
  }

  &:not(.-visible) {
    --inset-x: 15%;
    --inset-y: 15%;
    --scale: 0.8;
  }

  > .video {
    overflow: hidden;
    width: 100%;
    height: auto;
    transform: scale(var(--scale));
    transition:
      transform 550ms,
      clip-path 550ms;
    clip-path: inset(
      var(--inset-y) var(--inset-x) var(--inset-y) var(--inset-x) round 8px
    );

    video {
      border-radius: 0.5rem;
    }

    @media (--vs) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &:hover {
      .custom-video-controls {
        &.-visible {
          opacity: 1;
        }
      }
    }
  }
}

.custom-video-controls {
  position: absolute;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  mix-blend-mode: difference;

  @media (--vs) {
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    &.-visible {
      opacity: 1;
    }
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--vl) {
      transition: transform 0.1s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .progress {
    cursor: pointer;
    width: 100%;
    height: 0.33rem;
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    .progress-bar {
      height: 100%;
      background-color: white;
      width: 0%; /* Controlled dynamically */
      transition: width 0.1s;
    }
  }
}
</style>
