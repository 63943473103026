import type { Component } from 'vue';
import ContentText from '~/components/modules/ContentText.vue';
import ContentRichtext from './ContentRichtext.vue';
import ContentFigure from '~/components/modules/ContentFigure.vue';
import ContentTestimonial from '~/components/modules/ContentTestimonial.vue';
import TeaserTeam from '~/components/modules/TeaserTeam.vue';
import ContentBenefits from '~/components/modules/ContentBenefits.vue';
import GridonicWay from '~/components/modules/GridonicWay.vue';
import ContentImage from '~/components/modules/ContentImage.vue';
import ContentTeamSlider from '~/components/modules/ContentTeamSlider.vue';
import ContentVideo from '~/components/modules/ContentVideo.vue';
import ContentShowreel from '~/components/modules/ContentShowreel.vue';
import ContentImageMultiple from '~/components/modules/ContentImageMultiple.vue';
import ContentCta from '~/components/modules/ContentCta.vue';
import ContentLogowall from '~/components/modules/ContentLogowall.vue';
import ProjectsBig from '~/components/modules/ProjectsBig.vue';
import ProjectsSimple from '~/components/modules/ProjectsSimple.vue';
import DeepDiveText from '~/components/modules/DeepDiveText.vue';
import DeepDiveTeam from '~/components/modules/DeepDiveTeam.vue';
import DeepDiveAsset from '~/components/modules/DeepDiveAsset.vue';
import ProjectsTeaser from '~/components/modules/ProjectsTeaser.vue';

export const ModuleComponents: Record<string, Component> = {
  ContentTextRecord: ContentText,
  ContentRichtextRecord: ContentRichtext,
  ContentFigureRecord: ContentFigure,
  ContentTestimonialRecord: ContentTestimonial,
  TeaserTeamRecord: TeaserTeam,
  ContentBenefitRecord: ContentBenefits,
  GridonicWayRecord: GridonicWay,
  ContentImageRecord: ContentImage,
  ContentTeamsliderRecord: ContentTeamSlider,
  ContentVideoRecord: ContentVideo,
  ContentShowreelRecord: ContentShowreel,
  ContentImageMultipleRecord: ContentImageMultiple,
  ContentCtaRecord: ContentCta,
  ContentLogowallRecord: ContentLogowall,
  ProjectsBigRecord: ProjectsBig,
  ProjectsSimpleRecord: ProjectsSimple,
  DeepDiveTextRecord: DeepDiveText,
  DeepDiveTeamRecord: DeepDiveTeam,
  DeepDiveAssetRecord: DeepDiveAsset,
  ProjectsTeaserRecord: ProjectsTeaser,
};
