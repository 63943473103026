<template>
  <div class="content-richtext">
    <div class="container">
      <div
        ref="textElement"
        class="text richtext-content text-highlight-content"
        :class="[{ '-visible': isSectionVisible }]"
      >
        <StructuredText
          v-if="data.richtext"
          :class="[`-${textGradient}`]"
          :data="data.richtext"
          class="text-md structured-text"
          :render-block="renderBlock"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { StructuredText } from 'vue-datocms';
import { SplitText } from 'gsap/SplitText';
import renderBlock from '~/datocms/renderBlock';
import type { ContentRichtextFragment } from '#gql';
import { useAnimateOnReady } from '~/utils/useAnimateOnReady';

const props = defineProps<{
  data: ContentRichtextFragment;
}>();

const textGradient = props.data.gradient ?? 'blue';

const isSectionVisible = ref<boolean>(false);
const textElement = ref<HTMLElement | null>(null);

const runningOnLeave: (() => void)[] = [];

const { stop } = useIntersectionObserver(
  textElement,
  ([{ isIntersecting }]) => {
    isSectionVisible.value = isIntersecting;
  },
  {
    threshold: 0,
    rootMargin: '-100px',
  },
);

onMounted(() => {
  if (textElement.value) {
    const split = new SplitText(textElement.value, {
      type: 'words',
      linesClass: 'line',
      wordClass: 'word',
    });

    const wordsNotNested = split.words.map(function (word) {
      if (
        word.parentElement instanceof HTMLElement &&
        word.parentElement.tagName === 'MARK'
      ) {
        return word.parentElement;
      }

      return word;
    });

    const { start, kill } = animateWords(textElement.value, wordsNotNested);

    useAnimateOnReady(start);

    runningOnLeave.push(kill);
  }
});

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});

function animateWords(textElement: HTMLElement, words: Element[]) {
  return { start, kill };

  function start() {
    gsap.fromTo(
      words,
      {
        opacity: 0.1,
      },
      {
        ease: 'none',
        opacity: 1,
        stagger: 0.05,
        scrollTrigger: {
          trigger: textElement,
          start: 'top bottom-=20%',
          end: 'center top+=20%',
          scrub: true,
        },
      },
    );
  }

  function kill() {
    gsap.killTweensOf(words);
  }
}
</script>

<style scoped lang="scss">
.content-richtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: var(--base-component-padding);

  :deep(mark > div) {
    position: unset !important;
  }

  @media (--vl) {
    min-height: 60dvh;
  }

  > .container {
    margin: auto 0;

    > .text {
      color: #ffffff;
      max-width: 80ch;
      padding-top: 1rem;
      padding-bottom: 1rem;

      > :deep(p) {
        padding: 1rem 0;
      }

      :deep(a) {
        text-decoration: underline;
      }
    }
  }
}
</style>
